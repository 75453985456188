<template>
  <div id="RestockLists-container">
    <!-- SEARCH -->
    <div class="search">
      <el-form
        ref="form"
        :model="form"
        label-width="100px"
        label-position="left"
        :inline="true"
      >
        <el-form-item label="订单编号:">
          <el-input
            v-model="form.serialNumber"
            placeholder="请输入订单编号"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品名称:">
          <el-input
            v-model="form.goodsName"
            placeholder="请输入商品名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="订单状态：">
          <el-select v-model="form.shippingState" placeholder="请选择订单状态">
            <el-option label="待支付" value="0"></el-option>
            <el-option label="待发货" value="3"></el-option>
            <el-option label="待入仓" value="4"></el-option>
            <el-option label="已入仓" value="5"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期选择">
          <el-select v-model="form.selectTime" placeholder="请选择日期">
            <el-option label="当日" value="1"></el-option>
            <el-option label="近七天" value="2"></el-option>
            <el-option label="近一个月" value="3"></el-option>
            <el-option label="近3个月" value="4"></el-option>
            <el-option label="近半年" value="5"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" icon="el-icon-search"
            >搜索</el-button
          >
          <el-button
            type="success"
            icon="el-icon-refresh"
            plain
            @click="refreshForm"
            >刷新</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="line"></div>
    <!-- IMPORT -->
    <el-button type="warning" class="exportButton" rain @click="exportEXCEL"
      >导出Excel</el-button
    >
    <!-- TABLE -->
    <el-table
      :data="tableData"
      border
      style="width: 100%"
      :header-cell-style="{
        'text-align': 'center',
        background: '#f2f2f2',
        'font-size': '14px',
        'font-weight': '400',
      }"
      :cell-style="{ 'text-align': 'center' }"
      :fit="true"
    >
      <el-table-column type="index" width="50"> </el-table-column>
      <el-table-column prop="serialNumber" label="订单编号" width="180">
      </el-table-column>
      <el-table-column prop="totalAmount" label="订单金额"> </el-table-column>
      <el-table-column prop="orderDetailList[0].cost_price" label="单价">
      </el-table-column>
      <el-table-column
        prop="orderDetailList[0].productName"
        label="商品名称"
        width="200"
      >
      </el-table-column>
      <el-table-column prop="orderDetailList[0].groupName" label="规格">
      </el-table-column>
      <el-table-column prop="orderDetailList[0].quantity" label="数量">
      </el-table-column>
      <el-table-column prop="shippingState" label="订单状态">
        <template slot-scope="scope">
          <div>
            {{ $dict.restockOrderState | dictFormat(scope.row.shippingState) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="orderCreateTime" label="下单时间" width="180">
      </el-table-column>
      <el-table-column prop="emsName" label="物流公司"> </el-table-column>
      <el-table-column prop="orderDetailList[0].shipNumber" label="运单号">
      </el-table-column>
      <el-table-column
        prop="orderDetailList[0].confirmReceiptTime"
        label="确认收货时间"
      >
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="180">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="lookOrder(scope.row)"
            >查看
          </el-button>
          <el-button
            v-if="scope.row.shippingState === 4"
            type="text"
            size="small"
            @click="confirmInHold(scope.row.orderId)"
            >确认入仓
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- PAGEGATION -->
    <el-pagination
      background=""
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="10"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      class="pageGation"
    >
    </el-pagination>
    <!-- DRAWER -->
    <el-drawer
      :visible.sync="drawer"
      title="进货订单详情"
      size="60%"
    >
      <div class="drawer-content">
        <div class="messageBox">
          <div class="drawr-top">基本信息</div>
          <div class="image">
            <el-image
              style="width: 100px; height: 100px"
              :src="url"
              fit="cover"
            ></el-image>
          </div>
          <!-- CONTENT -->
          <div class="shopMessage">
            <el-row :gutter="20" class="row">
              <el-col :span="12">
                <span> 订单编号:</span>
                <span class="shop-text">{{ shopInfo.orderNumber }}</span>
              </el-col>
              <el-col :span="12">
                <span>订单状态:</span>
                <span class="shop-text">
                  {{
                    $dict.restockOrderState | dictFormat(shopInfo.shippingState)
                  }}
                </span>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <span>下单时间:</span>
                <span class="shop-text">{{ shopInfo.orderCreateTime }}</span>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <span>物流信息:</span>
                <span>
                  <el-button
                    v-if="isWuliuOrder"
                    type="text"
                    size="small"
                    @click="getWuliuIfo(shopInfo)"
                    >查看物流</el-button
                  >
                </span>
              </el-col>
            </el-row>
          </div>
          <div class="shop">
            <div class="drawr-top">商品信息</div>
            <div class="shop-table">
              <el-table
                :data="shopData"
                border
                style="width: 100%"
                :header-cell-style="{
                  'text-align': 'center',
                  'font-size': '14px',
                  'font-weight': '400',
                }"
                :cell-style="{ 'text-align': 'center' }"
                show-summary
              >
                <el-table-column prop="productId" label="商品ID" align="center">
                </el-table-column>
                <el-table-column prop="productName" label="商品名称" align="center">
                </el-table-column>
                <el-table-column prop="price" label="价格" align="center"> </el-table-column>
                <el-table-column prop="groupName" label="规格" align="center">
                </el-table-column>
                <el-table-column prop="quantity" label="数量" align="center">
                </el-table-column>
                <el-table-column prop="totalAmount" label="小计" align="center"> </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
    <!-- DIALOG -->
    <!-- 查看物流 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="30%"
      title="物流信息"
      top="10"
      :before-close="handleClose"
    >
      <div class="line"></div>
      <div class="wuliuBox">
        <el-row :gutter="20" class="row">
          <el-col :span="5" style="margin-top:5px">物流公司:</el-col>
          <el-col :span="6" style="margin-top:10px">{{
            wuliuDetails.com
          }}</el-col>
        </el-row>
        <el-row :gutter="20" class="row">
          <el-col :span="5" style="margin-top:5px">运单号:</el-col>
          <el-col :span="8" style="margin-top:10px">{{
            wuliuDetails.number
          }}</el-col>
        </el-row>
        <el-row :gutter="20" class="row">
          <el-col :span="5" style="margin-top:5px">物流状态:</el-col>
          <el-col :span="6" style="margin-top:10px">
            <template>
              <div>
                {{ $dict.wuliuStatus | dictFormat(wuliuDetails.status) }}
              </div>
            </template>
          </el-col>
        </el-row>
        <el-row :gutter="20" class="row">
          <el-col :span="5" style="margin-top:5px">物流详情:</el-col>
          <el-col :span="16" style="margin-top:10px">
            <el-timeline :reverse="true">
              <el-timeline-item
                v-for="(activity, index) in wuliuDetails.traces"
                :key="index"
                :timestamp="activity.time"
              >
                {{ activity.desc }}
              </el-timeline-item>
            </el-timeline>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { formatSubForm } from "@/utils/tools";
export default {
  name: "RestockLists-index",
  data() {
    return {
      isWuliuOrder: null, //是否有物流订单
      shopInfo: {},
      dialogVisible: false,
      total: null,
      url:
        "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      drawer: false,
      selectTimes: [1, 2, 3, 4, 5],
      state: [0, 3, 4, 5],
      wuliuData: {}, //物流信息
      wuliuDetails: [], // 物流详情
      // 表单部分
      form: {
        serialNumber: null, //订单编号
        date1: null,
        status: null, // value = "支付方式（0待支付,1,待上级确认,2已付现金未付贝,3待发货, 4待收货/已发货,5已完成/未评价,6已评价,7退款成功,8申请退款,9土地已带看,10土地已确认）", paramType = "form"),
        pageNum: 1, // value = "页数", paramType = "form"),
        pageSize: 10, // value = "条数", paramType = "form"),
        remark: 5, // value = "购买方式（1直接购买,2发起求购，3匀贸普通购买，4匀贸动感地带，5厂家进货,100库存不足,101购物券不足）", paramType = "form"),
        refund: null, // value = "是否是退款订单（1、是；0、否）", paramType = "form"),
        goodsName: null, // value = "商品名称", paramType = "form"),
        selectTime: null, // value = "查询时间(1、当日，2、近七天，3、近一月，4、近三月，5、近半年)", paramType = "form"),
        paymentType: null, // value = "支付方式(1、购物券，纯券支付，2、现金，支付宝、微信不加购物券，3、购物券+现金，混合支付)", paramType = "form"),
        wlStatus: null, // value = "配送方式(0物流1自提)", paramType = "form")
        shippingState: null, //订单状态
      },
      // 表格部分
      tableData: [], //厂商订单
      shopData: [], //商品详情

      //底部分页
      currentPage: 1,
      linkUrl: "https://www.kuaidi100.com/?from=openv",
    };
  },
  created() {
    this.getShopOrders();
  },
  components: {},
  methods: {
    // api/slborder/ymOrderList
    // 获取厂商订单
    getShopOrders() {
      const params = formatSubForm(this.form);
      const data = this.form;
      this.$api.order
        .getRestockList({
          pageNum: data.pageNum,
          pageSize: data.pageSize,
          remark: data.remark,
          goodsName: data.goodsName,
          serialNumber: data.serialNumber,
          status: data.shippingState,
          selectTime: data.selectTime,
        })
        .then((res) => {
          const { data, success, message } = res;
          this.tableData = data.list;
          this.total = parseInt(data.total);
          if (!success) {
            this.$message.error(message);
            return;
          }
        })
        .catch((err) => {
          console.log("获取厂商订单失败", err);
        });
    },
    //确认入仓
    confirmInHold(id) {
      this.$confirm(`是否将该商品入仓?`, "确认入仓", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await this.$api.order.confirmInHold({ orderId: id });
          this.$message({
            type: "success",
            message: "入仓成功!",
          });
          this.getShopOrders();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消入仓",
          });
        });
    },
    //查看订单
    lookOrder(row) {
      this.shopInfo = row;
      this.url = this.shopInfo.orderDetailList[0].goodImageUrl;
      this.shopData = this.shopInfo.orderDetailList;
      // 是否有物流订单
      this.isWuliuOrder = this.shopInfo.orderDetailList[0].shipNumber !== null;
      this.drawer = true;
    },
    //查看物流
    async getWuliuIfo(shopInfo) {
      // this.dialogVisible = true;
      const item = shopInfo.orderDetailList[0].shipNumber;
      // 模拟 输入框
      var cInput = document.createElement("input");
      cInput.value = item;
      document.body.appendChild(cInput);
      cInput.select(); // 选取文本框内容
      // 执行浏览器复制命令
      // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签）
      // Input要在正常的编辑状态下原生复制方法才会生效
      document.execCommand("Copy");
      // 复制成功后再将构造的标签 移除
      document.body.removeChild(cInput);
      // window.location.href = this.linkUrl;
      window.open(this.linkUrl, "_blank");
      // const orderId = shopInfo.orderId;
      // try {
      //   const { data: res1 } = await this.$api.order.acquiringLogisticsInfo({
      //     number: shipNumber,
      //   });
      //   if (res1.data !== null) {
      //     this.wuliuDetails = res1.data;
      //   } else {
      //     this.wuliuDetails = {};
      //   }

      //   // const { data: res2 } = await this.$api.order.queryShipNumber({
      //   //   orderId: orderId,
      //   // });
      //   // this.wuliuData = res2;
      //   // const { data: res3 } = await this.$api.order.emsList();
      // } catch (error) {
      //   this.dialogVisible = false;
      //   console.log(error);
      // }
    },
    onSubmit() {
      this.currentPage = 1;
      this.getShopOrders();
    },
    refreshForm() {
      this.form = {};
      this.currentPage = 1;
      this.form.pageNum = 1;
      this.form.pageSize = 10;
      this.form.remark = 5;
      this.getShopOrders();
    },
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.getShopOrders();
    },
    handleCurrentChange(val) {
      this.form.pageNum = val;
      this.getShopOrders();
    },
    handleClose(done) {
      this.dialogVisible = false;
    },

    // 导出EXcel
    exportEXCEL() {
      this.$api.file
        .exportEXCEL(this.form, "api/backstage/doExportVendorExcel")
        .then((res) => {
          // console.log(res);
          let reader = new FileReader();
          reader.readAsDataURL(res); //转换为base64，可以直接放入a标签href   接受的数据一定要配置responseType: blob
          reader.onload = function(e) {
            let a = document.createElement("a");
            a.download = ""; //此处填写文件地址
            a.href = e.target.result;
            document.body.appendChild(a); // 修复firefox中无法触发click
            a.click();
            document.body.removeChild(a);
          };
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#RestockLists-container {
  padding: 20px;
  .line {
    width: 100%;
    height: 1px;
    background-color: #f3f3f3;
    margin-bottom: 20px;
  }
  .exportButton {
    width: 100px;
    margin-bottom: 10px;
    border: 0;
  }
  // TABLE
  .thead {
    background: #f40;
  }
  .pageGation {
    float: right;
    margin-top: 20px;
  }
  // DRAWER
  .drawer-content {
    background: #c0c4cc;
    width: 100%;
    height: 100%;
    padding: 30px 30px 0 30px;
    .messageBox {
      background: #ffffff;
      border-radius: 10px;
      height: auto;
      padding: 40px 20px;
      // box-shadow: 5px 5px 10px 1px #ffffff;
      // 基本信息
      .drawr-top {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 10px;
      }

      .el-row {
        margin: 20px 0;
      }
    }
    .wuliuBox {
      display: flex;
      flex-direction: row;
    }
    .shop-text {
      color: #0078d4;
    }
  }
}
</style>
